import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';


import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import Loading from './components/Loading'


import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import imageHome from './assets/desktop/top-site-revomtransportes-desk.png';
import logo from './assets/logo.png';

function App() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = () => setShow(false);

  const notifySuccess = () => toast("Dados enviado com sucesso!");
  const notifyError = () => toast.error("Erro ao enviar os dados");


  async function sendForm() {

    if(name === "" || phone === ""  || email === "" ) {
      toast.error("Preencha todos os campos obrigatórios");
      return;
    }

    setLoading(true);
    const data = {
      name,
      email,
      phone,
      enterprise,
      message
    }
    try {
      setTimeout(() => setLoading(false), 3000);
      await axios.post(`https://revom-97753448fdbf.herokuapp.com/contact`, data);
      notifySuccess();
      setShow(false);
      
      setName('')
      setEmail('')
      setPhone('')
      setEnterprise('')
      setMessage('')
      
    } catch(err) {
      notifyError();
      setLoading(false);
    }
  }


  return loading ? <Loading /> : (
    <div className="App">
       <ToastContainer />
      <div className="image-container">
        <img
          src={imageHome}
          alt="Imagem"
          className='image-top'
        />
        </div>

        <div className="content">
        <div className="logo-container">
          <div className='centered'>
            <img
              src={logo}
              alt="Logo"
              className="logo"
            />
          <p className='slogan'>Transportar é o que nos move</p>
          </div>

        <p className="centered-text">
          Nos movemos para melhor lhe atender!
        </p>
          <p className="logo-text">
           A <strong style={{ color: '#132839' }}>REVOM</strong> atua no transporte de cargas fechadas para todo o Brasil sempre com responsabilidade e compromisso para melhor atender as necessidades dos clientes e parceiros.
          </p>    
        <button onClick={() => setShow(true)} style={{ backgroundColor: '#FFB10B', border: 'none', marginTop: 30, width: 200, height: 41, borderRadius: 8, outline: '1px solid #FFF', color: '#132839', fontSize: 15, fontWeight: '600' }}>
          Orçamento de frete
        </button>
        </div>
        </div>
      <footer className="footer">
       <p>REVOM TRANSPORTES DE CARGAS</p> 
       <p style={{ marginTop: -8}}>CNPJ: 49.920.018/0001-09</p>
      </footer>

      <Modal show={show} onHide={handleClose} className="custom-modal" backdrop="static" keyboard={false}>
      <div className="header">
            <div className="empty"></div>
            <button className="button-modal" onClick={() => setShow(false)}>X</button>
        </div>
        <div className="modal-texts">
          <h3>
            Solicite seu orçamento e entraremos em contato
          </h3>
          <h5>Todos os campos com * são obrigatórios</h5>
        </div>
        <div className="withoutOpacity">
          <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="text" placeholder="Digite o seu nome*" value={name} onChange={text => setName(text.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="email" placeholder="Digite o seu email*"  value={email} onChange={text => setEmail(text.target.value)} />
          </Form.Group>

          <InputMask mask="(99) 99999-9999" maskChar=" " value={phone} onChange={text => setPhone(text.target.value)}>
            {(inputProps) => (
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Telefone (WhatsApp)*"  />
              </Form.Group>
            )}
          </InputMask>

          
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="text" placeholder="Empresa"  value={enterprise} onChange={text => setEnterprise(text.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Control className="inputs" as="textarea" placeholder='Mensagem' rows={5}  value={message} onChange={text => setMessage(text.target.value)} />
            </Form.Group>
          </Form>
          <button 
            style={{ backgroundColor: '#FFB10B', border: 'none', marginTop: 30, width: '100%', height: 41, borderRadius: 8, outline: '1px solid #FFF', color: '#132839', fontSize: 15, fontWeight: '600' }}
            onClick={() => sendForm()}
            >
            Enviar
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default App;
